import axios from "axios";
import { getAuth } from "firebase/auth";

import DataDefinitions from "../data/DataTerms.json";

let cancelRequest;

// const searchURL = "http://localhost:4000";
// const searchURL = "http://137.184.189.32:4000";
const searchURL = 'https://searchapi-test.nanocrowd.io'
// const searchURL = 'https://searchapi-prod.nanocrowd.io'

const searchApiClient = axios.create({
  baseURL: `${searchURL}`,
  // timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const baseURL = "https://nc-data-api-test.nanocrowd.io";
// const baseURL = "https://nc-data-api.nanocrowd.io";
// const baseURL = 'http://localhost:2358'

const apiClient = axios.create({
  baseURL: `${baseURL}`,
  // timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  async search(queryString) {
    // avoid sending queries that are the selected item in the dropdown
    if (
      queryString.trim().length === 0 ||
      (queryString.trim().length > 0 && queryString.includes(" • "))
    ) {
      return;
    }
    const auth = getAuth();
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true);

      if (cancelRequest) {
        cancelRequest();
      }

      try {
        return await searchApiClient({
          method: "POST",
          url: `/api/search`,
          headers: {
            AuthToken: idToken,
          },
          data: {
            queryString,
          },
          cancelToken: new axios.CancelToken((c) => {
            cancelRequest = c;
          }),
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled");
        } else {
          console.error("Failed to fetch suggestions:", error);
        }
      }
    }
  },
  async getTitle(ncTitleId) {
    const auth = getAuth();
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true);

      return await apiClient({
        method: "GET",
        url: `/api/titles/details/${ncTitleId}`,
        headers: {
          AuthToken: idToken,
        },
      });
    }
  },
  async getDataDefinitions() {
    const auth = getAuth();
    if (auth.currentUser) {
      return { data: DataDefinitions };
    }
  },
};
